"use client";
import { CheckDuploMensagemSVG, CheckUnicoMensagem } from "@/components/icons/MensagemSVG";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { TipoMensagemEnum } from "@/util/ChatUtils";
import { formatarDataCurta } from "@/util/DateUtils";
import { formatarTelefone } from "@/util/FormatacaoUtils";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import OpcoesConversa from "./OpcoesConversa";

const i18n = ptBR;

interface ConversatioBoxProps {
    conversa: ConversaType;
    selecionado?: boolean;
}

const CaixaConversa: React.FC<ConversatioBoxProps> = ({ conversa, selecionado }) => {
    const router = useRouter();
    const pathname = usePathname();

    const [mostrarOpcoes, setMostrarOpcoes] = useState(false);
    const { alterarConversaAtiva } = useConversaStore();

    const clickConversa = () => {
        alterarConversaAtiva(conversa);
        router.replace(pathname);
    };

    const renderCheckStatusMensagem = () => {
        if (
            !conversa.ultimaMensagem ||
            conversa.ultimaMensagem?.remetente?.toLocaleLowerCase() === conversa.nomeContato?.toLocaleLowerCase()
        )
            return null;

        if (conversa.ultimaMensagem?.statusEnvio === "ENVIADA") {
            return <CheckUnicoMensagem />;
        } else {
            return (
                <CheckDuploMensagemSVG fill={conversa.ultimaMensagem?.statusLeitura === "LIDA" ? "#53bdeb" : "gray"} />
            );
        }
    };

    const renderTagConversa = () => {
        return (
            <div className="flex gap-1">
                {conversa.usuarioResponsavel && (
                    <span className="text-xs font-semibold p-1 rounded-full bg-orange-400">{`${conversa.usuarioResponsavel.login}`}</span>
                )}
                {conversa.setor && (
                    <span className="text-xs font-semibold p-1 rounded-full bg-blue-400">{`${conversa.setor}`}</span>
                )}
            </div>
        );
    };

    const retornaTextoConversa = (ultimaMensagem: MensagemType | undefined) => {
        if (!ultimaMensagem) return "";

        switch (ultimaMensagem.tipo) {
            case TipoMensagemEnum.TEXT:
                return ultimaMensagem.texto;
            case TipoMensagemEnum.IMAGE:
                return i18n.lbl_enviou_imagem;
            case TipoMensagemEnum.VIDEO:
                return i18n.lbl_enviou_video;
            case TipoMensagemEnum.AUDIO:
                return i18n.lbl_enviou_audio;
            case TipoMensagemEnum.DOCUMENT:
                return i18n.lbl_enviou_arquivo;
            case TipoMensagemEnum.CONTACTS:
                return i18n.lbl_enviou_contato;
            case TipoMensagemEnum.LOCATION:
                return i18n.lbl_enviou_localizacao;
            default:
                return ultimaMensagem.texto ? ultimaMensagem.texto : i18n.lbl_enviou_mensagem;
        }
    };

    const renderMensagemNaoLida = () => {
        const totalMensagens = conversa?.totalNovasMensagens ?? 0;
        const naoLida = conversa?.naoLida;
        if (totalMensagens > 0) {
            return <span className={`text-sm text-slate-100 px-2 rounded-full bg-green-700`}>{totalMensagens}</span>;
        } else if (naoLida) {
            return <span className={`text-sm text-slate-100 p-2 rounded-full bg-green-700`} />;
        }
    };
    return (
        <div
            className={`group relative w-full h-16 flex items-center border-b
                    hover:bg-neutral-300 transition cursor-pointer
                    ${selecionado ? "bg-neutral-300" : "bg-neutral-50"}`}>
            <button
                className="min-w-0 flex-1 px-2 py-4 "
                onClick={clickConversa}>
                <div className="flex justify-between items-center">
                    <span className="truncate text-md font-semibold text-gray-900">
                        {formatarTelefone(conversa.nomeContato ?? conversa.telContato)}
                    </span>
                    <div className="flex gap-2 ml-4">
                        {renderTagConversa()}
                        {conversa.ultimaMensagem?.dataHoraEnvio && (
                            <p className="text-sm text-gray-500">
                                {formatarDataCurta(conversa?.ultimaMensagem?.dataHoraEnvio.toString())}
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex items-baseline gap-1">
                        {renderCheckStatusMensagem()}
                        <span className={`truncate text-left text-sm text-gray-500 w-80`}>
                            {retornaTextoConversa(conversa.ultimaMensagem)}
                        </span>
                    </div>

                    {renderMensagemNaoLida()}
                </div>
            </button>
            <button
                className={`hidden bg-green-700 h-full transition group-hover:block group-hover:box-border group-hover:px-2`}
                onClick={() => setMostrarOpcoes(!mostrarOpcoes)}>
                <FontAwesomeIcon
                    icon={faAngleDown}
                    size="sm"
                    inverse
                />
            </button>
            <OpcoesConversa
                conversa={conversa}
                mostrarOpcoes={mostrarOpcoes}
                clickOpcao={() => setMostrarOpcoes(!mostrarOpcoes)}
            />
        </div>
    );
};

export default CaixaConversa;
