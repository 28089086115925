/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import { EsqueletoMensagens } from "@/components/carregamento/EsqueletoMensagens";
import CaixaMensagem from "@/components/chat/conversa/mensagem/CaixaMensagem";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { agruparMensagensPorData } from "@/util/ChatUtils";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

interface CorpoConversaProps {
    setMensagemResposta: React.Dispatch<React.SetStateAction<MensagemType | undefined>>;
}

const i18n = ptBR;

const CorpoConversa = ({ setMensagemResposta }: CorpoConversaProps) => {
    const [conversaAnterior, setConversaAnterior] = useState<ConversaType | null>(null);
    const {
        conversa,
        carregando,
        alteraCarregando,
        adicionarListaMensagens,
        desconectarConversaAntiga,
        mensagens,
        mensagemEnviadaPeloUsuario,
        paginaMensagens,
        tamanhoPagina,
        alterarPaginaMensagens,
        totalPaginas,
        alterarTotalPaginas,
    } = useConversaStore();

    const mensagensContainerRef = useRef<HTMLDivElement>(null);
    const mensagemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    const carregarListaMensagensPaginadas = async (
        codConversa: string,
        signal: AbortSignal,
        alterarStatusCarregando: boolean = true
    ) => {
        try {
            if (alterarStatusCarregando) {
                alteraCarregando(true);
            }

            const url = `${process.env.NEXT_PUBLIC_CHAT_API_REST_GET_MENSAGENS_CONVERSA_URL}?codConversa=${codConversa}&pagina=${paginaMensagens}&tamanho=${tamanhoPagina}`;
            const retorno = await fetch(url, { cache: "no-store", signal });
            if (!retorno.ok) throw new Error(i18n.err_buscar_mensagens);
            const mensagens = await retorno.json();
            adicionarListaMensagens(mensagens.content);
            alterarPaginaMensagens(paginaMensagens + 1);
            alterarTotalPaginas(mensagens.page.totalPages);
        } catch (erro: any) {
            if (erro.name !== "AbortError") {
                toast(erro.message, { toastId: "erro-retornar-mensagens", type: "error" });
            }
        } finally {
            if (alterarStatusCarregando) {
                alteraCarregando(false);
            }
        }
    };

    const carregaMensagensIniciais = useCallback(
        async (codConversa: string, signal: AbortSignal) => {
            carregarListaMensagensPaginadas(codConversa, signal);
        },
        [alteraCarregando]
    );

    useEffect(() => {
        const abortController = new AbortController();
        if (conversa) {
            setConversaAnterior(conversa);
            carregaMensagensIniciais(conversa.codConversa, abortController.signal);
        }

        return () => {
            desconectarConversaAntiga(conversaAnterior?.codConversa);
            abortController.abort();
        };
    }, [conversa]);

    useEffect(() => {
        console.log("Caiu no useEffect do scrollTop");
        if (mensagensContainerRef.current) {
            console.log("Caiu no if do scrollTop");
            mensagensContainerRef.current.scrollTop = mensagensContainerRef.current.scrollHeight;
        }
    }, [carregando, mensagemEnviadaPeloUsuario]);

    const renderMensagens = () => {
        const abortController = new AbortController();
        if (!conversa || mensagens.length === 0) {
            return (
                <div className="w-full mt-40 mx-auto my-auto text-center ">
                    <span>{i18n.lbl_nenhuma_mensagem_conversa}</span>
                </div>
            );
        }

        const mensagensAgrupadas = agruparMensagensPorData(mensagens);
        return (
            <>
                {totalPaginas >= paginaMensagens && (
                    <div className="flex my-5 justify-center top-2">
                        <button
                            onClick={() =>
                                carregarListaMensagensPaginadas(conversa.codConversa, abortController.signal, false)
                            }
                            className="px-3 py-2 bg-green-700 text-sm text-neutral-100 rounded-full">
                            {i18n.btn_carregar_mais_mensagens}
                        </button>
                    </div>
                )}
                {Array.from(mensagensAgrupadas).map(([data, mensagensDia]) => (
                    <div key={data}>
                        <div className="flex mb-10 justify-center sticky top-2 z-10">
                            <span
                                className="px-3 py-2 absolute bg-green-700 text-sm 
                                text-neutral-100 rounded-full">
                                {data}
                            </span>
                        </div>
                        {mensagensDia.map((mensagem: MensagemType) => (
                            <div
                                key={mensagem.codMensagem}
                                ref={(el) => {
                                    mensagemRefs.current[mensagem.codMensagem] = el;
                                }}>
                                <CaixaMensagem
                                    mensagem={mensagem}
                                    nomeContato={conversa.nomeContato}
                                    setMensagemResposta={setMensagemResposta}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </>
        );
    };

    return (
        <div
            className="flex-1 overflow-y-auto bg-chat-white"
            ref={mensagensContainerRef}>
            {carregando ? <EsqueletoMensagens /> : renderMensagens()}
        </div>
    );
};

export default CorpoConversa;
