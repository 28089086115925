"use client";
import { retornaInfoUsuarioLogado } from "@/api/chatAPI";
import Sidebar from "@/components/chat/barraLateral/Sidebar";
import ChatMain from "@/components/chat/conversa/Container";
import Main from "@/components/main/Main";
import Modal from "@/components/modal/Modal";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { CookieEnum, isNullOrEmpty } from "@/util/ChatUtils";
import { getCookie } from "cookies-next";
import { useRouter, useSearchParams } from "next/navigation";
import { Suspense, useEffect } from "react";
import { toast } from "react-toastify";

const i18n = ptBR;

function ConteudoHome() {
    const router = useRouter();
    const searchParams = useSearchParams();
    const { alterarConversaAtiva, alteraCarregando } = useConversaStore();
    const { alterarUsuario, removerUsuario } = useUsuarioStore();

    useEffect(() => {
        const telefone = searchParams.get("telefone");

        if (!isNullOrEmpty(telefone)) {
            carregarConversaPorTelefone(telefone).finally(() => {
                alteraCarregando(false);
            });
        }

        if (process.env.NEXT_PUBLIC_AMBIENTE === "local") {
            return;
        }
        const loginUsuario = getCookie(CookieEnum.LOGIN_USUARIO);
        if (!loginUsuario) {
            removerUsuario();
            router.push("/login");
        }

        retornaInfoUsuarioLogado(loginUsuario as string)
            .then((usuario) => {
                alterarUsuario(usuario);
            })
            .catch((erro) => {
                toast(erro.message, {
                    toastId: "erro-info-usuario",
                    type: "error",
                });
            });
    }, []);

    const carregarConversaPorTelefone = async (telefone: string) => {
        try {
            const url = `${process.env.NEXT_PUBLIC_CHAT_API_REST_GET_RETORNAR_CONVERSA_POR_TELEFONE_URL}?telefone=${telefone}`;
            const retorno = await fetch(url, { cache: "no-store" });
            if (!retorno.ok) throw new Error(i18n.err_buscar_conversa);
            const conversa = await retorno.json();
            alterarConversaAtiva(conversa);
        } catch (erro: any) {
            if (erro.name !== "AbortError") {
                toast(erro.message, { toastId: "erro-retornar-conversa", type: "error" });
            }
        }
    };

    return (
        <Main>
            <Modal />
            <Sidebar />
            <ChatMain />
        </Main>
    );
}

export default function Home() {
    return (
        <Suspense fallback={<div>Carregando...</div>}>
            <ConteudoHome />
        </Suspense>
    );
}
